<div class="header-container">
  <div class="logo-container">
    
    <a class="logo" href="#"><h2 class="text-primary">
      <img width="80%"  src="../../../../assets/images/22.png" alt="">
    </h2></a>
  </div>
</div>

<div class="header-container" *ngIf="isMobile">
  <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
    <nb-icon icon="menu-2-outline"></nb-icon>
  </a>
</div>
