import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header-custom',
  styleUrls: ['./header-custom.component.scss'],
  templateUrl: './header-custom.component.html',
})
export class HeaderCustomComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  public isMobile: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'light';

  userMenu = [ { title: 'Cerrar sesión' }];
  

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,  
              public authService:AuthService) {
                authService.subscriptionSession = authService.observerSession().subscribe((next)=>{
                  authService.callbackLazySession(next);
               });
  }

  public logout()  { 
    this.authService.logout()

   }


  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

      const {xl} = this.breakpointService.getBreakpointsMap();
      const {is} = this.breakpointService.getBreakpointsMap();
      const {xs} = this.breakpointService.getBreakpointsMap();
      const {sm} = this.breakpointService.getBreakpointsMap();
      const {md} = this.breakpointService.getBreakpointsMap();
      const {lg} = this.breakpointService.getBreakpointsMap();
      
      this.themeService.onMediaQueryChange()
        .pipe(
          map(([, currentBreakpoint]) => currentBreakpoint),
          takeUntil(this.destroy$),
        )
        .subscribe(currentBreakpoint => {
          this.isMobile = currentBreakpoint.width <= is || currentBreakpoint.width <= xs || currentBreakpoint.width <= sm || currentBreakpoint.width <= md || currentBreakpoint.width <= lg;
        });
      // ...
      this.menuService.onItemClick().subscribe(() => {
        if (this.isMobile) {
          this.sidebarService.collapse('custom-menu-sidebar');
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'custom-menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
